
import {
  apiModelList,
  apiSynchronizat,
  apiSynchronizatStore,
  apiChangestatus,
  apiCategoryList,
  apiFactoryLists,
  apiShopDelete,
} from "@/api/shop";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
  },
})
export default class Model extends Vue {
  /** S Data **/

  apiModelList = apiModelList;

  pager: RequestPaging = new RequestPaging();

  searchObj: Object = {
    title: "",
    cate_id: "",
    factory_id: "",
  };

  categoryList: any[] = [];
  factory_lists: any[] = [];
  syncLoading: boolean = true;
  /** E Data **/

  /** S Methods **/

  // 获取模型列表
  getShopLists(): void {
    this.pager.request({
      callback: apiModelList,
      params: {
        ...this.searchObj,
      },
    });
    console.log(this.pager);
  }

  // 模型分类列表
  getCategoryList() {
    apiCategoryList({}).then((res) => {
      this.categoryList = res.category_list;
    });
  }

  getFactoryList() {
    apiFactoryLists({}).then((res: any) => {
      this.factory_lists = res.lists;
    });
  }

  async synchronizat(): Promise<void> {
    this.syncLoading = false;

    await apiSynchronizat({});
    this.syncLoading = true;
  }

  async synchronizatBystore(): Promise<void> {
    this.syncLoading = false;
    await apiSynchronizatStore({});
    this.syncLoading = true;
  }

  // 修改状态
  async shopSwitchStatusEdit(status: any, id: number): Promise<void> {
    await apiChangestatus({ id, is_show: status });
    this.getShopLists();
  }

  // 删除商城
  async onShopDelete(row: any): Promise<void> {
    await apiShopDelete({ id: row.id });
    this.getShopLists();
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getShopLists();
  }

  // 去编辑
  toModelEdit(id: number | any) {
    this.$router.push({
      name: "model_edit",
      query: {
        id: id as any,
        mode: PageMode.EDIT,
      },
      params: {
        pager: this.pager as any,
        searchObj: this.searchObj as any,
      },
    });
  }

  // 修改超级管理员
  toSuperAdminUpdate(id: number | any): void {
    this.$router.push({
      path: "/shop/modify_account",
      query: {
        id: id,
      },
    });
  }

  // 去商家后台
  toShop(url: any, status: number) {
    if (status == 0) return this.$message.error("该商城暂未营业中");

    if (url.indexOf("https://") === 0) window.open(url);
    else if (url.indexOf("http://") === 0) window.open(url);
    else window.open("http://" + url);
  }

  /** E Methods **/

  /** S ods **/
  created() {
    const params: any = this.$route.params;
    if (params.pager) {
      (this.pager as any) = params.pager;
    }
    if (params.searchObj) {
      (this.searchObj as any) = params.searchObj;
    }
    this.getShopLists();
    this.getCategoryList();
    this.getFactoryList();
  }
}
